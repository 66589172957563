import Link from "next/link";
import { OptionsType, PageType } from "../../utils/baseTypes";
import { getPathNameArray, optimizeURL } from "../../utils/url";
import { sanitize } from "isomorphic-dompurify";

interface BreadcrumbProps {
  post: PageType;
  options?: OptionsType;
  breadcrumbsClasses?: string;
}

const titleize = (slug: string): string => {
  var words = slug.split("-");
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
};

export const Breadcrumbs = ({
  post,
  breadcrumbsClasses,
  options,
}: BreadcrumbProps) => {
  const paths = getPathNameArray(post.link);
  const postType = post.type;

  return (
    <nav
      className={`breadcrumbs is-hidden-mobile ${
        breadcrumbsClasses ? breadcrumbsClasses : "pb-6"
      }`}
    >
      <ul
        className="breacrumbs-list"
        aria-label="Breadcrumbs"
        role="navigation"
      >
        <li>
          <Link href="/" prefetch={false} aria-label="Zur Startseite">
            <span>Startseite</span>
          </Link>
          <span aria-hidden="true">{" » "}</span>
        </li>
        {post.type &&
          postType !== "projects" &&
          paths.map((path, i) => {
            if (i === paths.length - 1) return false;
            let path_link = "";
            for (let j = 0; j < paths.length; j++) {
              if (j <= i) path_link += `/${paths[j]}`;
            }
            if (i !== 1) {
              return (
                <li key={i}>
                  <MiddleLink path_link={path_link} path={path} />
                </li>
              );
            }
          })}
        {postType == "projects" && options && (
          <>
            {options.acf.projects_overview_page && (
              <li>
                <MiddleLink
                  key={"portfolio"}
                  path_link={
                    optimizeURL(options.acf.projects_overview_page.url) ||
                    "/portfolio"
                  }
                  path={options.acf.projects_overview_page.title}
                />
              </li>
            )}
          </>
        )}
        {postType == "search" && (
          <li>
            <MiddleLink
              key={"encyclopedia"}
              path_link={"/wiki"}
              path={"wiki"}
            />
          </li>
        )}
        {post.title?.rendered && (
          <li aria-current="page">
            <span
              className="breadcrumbs-active"
              dangerouslySetInnerHTML={{
                __html: sanitize(post.title.rendered),
              }}
            />
          </li>
        )}
      </ul>
    </nav>
  );
};

const MiddleLink = ({
  path_link,
  path,
}: {
  path_link: string;
  path: string;
}) => {
  return (
    <>
      <Link prefetch={false} href={path_link} aria-label={`Zur ${path}`}>
        <span className="is-capitalized">{titleize(path)}</span>
      </Link>
      <span>{" » "}</span>
    </>
  );
};
